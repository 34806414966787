import React, { useState, useEffect, useContext } from 'react'
import { Button, CircularProgress } from '@mui/material';
import { AppContext } from '../../App';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { makeStyles } from '@mui/styles';
import { RESCHEDULE_CONSULTATION, CREATE_CONSULTATIONS, GET_SERVER_TIME, GET_PLANS, CREATE_PAYMENT_URL, GET_DOCTOR_AVAILIBILITY_FOR_DATE } from '../../api/graphQL/query';
import { useMutation, useQuery } from '@apollo/client';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { TextareaAutosize } from '@mui/material';
import useAlert from '../../hooks/useAlert';
import { formatDateTime2, getAvailableTimeSlot } from '../helperFunctions';
import Loader from '../Loader';

const useStyles = makeStyles(() => ({
    cancelButton: {
        padding: "16px 24px !important",
        borderRadius: "8px !important",
        textTransform: "capitalize !important",
        borderColor: "#3E5EA9 !important",
        color: "#3E5EA9 !important",
        fontSize: "13px !important",
        fontWeight: "500 !important",
        fontFamily: "Euclid Circular A !important",
        width:'100%'
    },
    submitButton: {
        padding: "18px 24px !important",
        borderRadius: "8px !important",
        textTransform: "capitalize !important",
        backgroundColor: "#3E5EA9 !important",
        fontSize: "13px !important",
        fontWeight: "500 !important",
        fontFamily: "Euclid Circular A !important",
        width: '100%'
    },
}));

export default function Selectconsult({setPref, data}) {
    const classes = useStyles();
    const {displayAlert} = useAlert();
    const { loading: appLoading, setLoading: setAppLoading } = useContext(AppContext);
    
    const providerId = localStorage.getItem("providerId");
    const authToken = localStorage.getItem('authToken');
    const docId = data?.doctorData?._id;
    const id = data?._id;
    const patient = data?.patientData?._id
    const email = data?.patientData?.email;
    const symp = data?.symptoms?.map(symptom => ({ name: symptom.name }));
    
    const [plan, setPlan] = useState(null);
    const [time, setTime] = useState("");
    const [availableTime, setAvailableTime] = useState([]);
    const [reason, setReason] = useState("");
    const [date, setDate] = useState("");
    const callbackUrl = process.env.REACT_APP_FRONTEND_URL;
    
    const formattedDate = formatDateTime2(date, time);

    const { error:docErr, data:docData, loading:docLoad } = useQuery(GET_DOCTOR_AVAILIBILITY_FOR_DATE, {
        skip: !docId,
        variables: { id: docId, date: date },
        context: {
            headers: {
                Authorization: `Bearer ${authToken}`
            }
        }
    });

    useEffect(() => {
        if (docId) {
            const todayDate = dayjs().format('YYYY-MM-DD');
            setDate(todayDate);
            getDoctorsTimesByDate(todayDate, docId); 
        }
        return;
    }, [docId])

    const { loading: plansLoading, error: plansError, data: plansData } = useQuery(GET_PLANS, {
        skip: !authToken && !providerId ,
        variables: { id: providerId },
        context: {
            headers: {
                Authorization: `Bearer ${authToken}`
            }
        }
    });

    
    useEffect(() => {
        setPlan(plansData?.getPlans?.plan || []);
    }, [plan])

    const handlegetDoctors = (value) => {
        const formattedDate = value.format('YYYY-MM-DD');
        setDate(formattedDate);
        getDoctorsTimesByDate();
    };
    
    const getDoctorsTimesByDate = () => {
        setAvailableTime(docData?.getDoctorAvailabilityForDate?.times || []);
    };
    
    const { error:serErr, data:serData, loading:serLoad } = useQuery(GET_SERVER_TIME);
    

    const getServerTime = serData?.getServerTime?.timeLagosJsDate;

    const currentDateL = getServerTime?.split('').splice(0,15).join(''); 
    const currentDate = getServerTime
    var inputDate = date
    var dateComponents = inputDate.split("-");

    var year = dateComponents[0];
    var month = parseInt(dateComponents[1]) - 1; // JavaScript uses zero-based months
    var day = dateComponents[2];

    var months = [
        "Jan", "Feb", "Mar", "Apr", "May", "Jun",
        "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
    ];

    var dateObj = new Date(year, month, day);
    var formattedDateC = dateObj.toString().substr(0, 3) + " " + months[month] + " " + day + " " + year;


    const selectedSlot = currentDateL == formattedDateC
    ? getAvailableTimeSlot(availableTime, currentDate)
    : availableTime;

    const [rescheduleMutation, { loading, error }] = useMutation(RESCHEDULE_CONSULTATION);

    const rescheduleConsultation = async (e) => {
        e.preventDefault();
        
        try {
            const rescheduleResponse = await rescheduleMutation({
                variables: {
                    id: id,
                    time: formattedDate,
                    doctor: docId,
                    reason: reason
                },
                
                context: {
                    headers: {
                        Authorization: `Bearer ${authToken}`
                    }
                }
            });
            createConsultation();
        } catch (error) {
            displayAlert('error', 'Error updating consultation');
            console.error(error);      
        }
    }
    
    const [consultationMutation, { loading: consultLoading, error: errorLoading }] = useMutation(CREATE_CONSULTATIONS);
    const [paymentMutation, { loading:paymentLoading, error:paymentError }] = useMutation(CREATE_PAYMENT_URL);

    console.log(formattedDate,'formattedDateaxss')
    
    const createConsultation = async () => {

        function convertTime(inputTime) {
            const now = new Date(); // Get the current date
            const [hours, minutes] = inputTime.split(':'); // Split the input time into hours and minutes
            const year = now.getFullYear(); // Get the current year
            const month = now.getMonth() + 1; // Get the current month (Note: Months are zero-based)
            const day = now.getDate(); // Get the current day
          
            // Create a new date string in the desired format
            const dateString = `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}T${hours}:${minutes}:00+01:00`;
          
            return dateString;
          }

          const formattedTime = convertTime(time);


        
        try {
            setAppLoading(true);
            const consultationResponse = await consultationMutation({
                variables: {
                    patient: patient,
                    doctor: docId,
                    discomfortLevel: data?.discomfortLevel,
                    description: reason || undefined,
                    firstNotice: data?.firstNotice,
                    providerId: providerId,
                    contactMedium: data?.contactMedium,
                    time: formattedDate,
                    symptoms: symp,
                    createdThrough:"weblink"
                },
                
                context: {
                    headers: {
                        Authorization: `Bearer ${authToken}`
                    }
                }
            });
            localStorage.setItem("consultationInfo", JSON.stringify(consultationResponse?.data?.createConsultation?.consultation));
            const itemId = consultationResponse?.data?.createConsultation?.consultation?._id

            const paymentUrlResponse = await paymentMutation({
                variables: {
                    user: patient,
                    amount: plan[0].amount.toString(),
                    email: email,
                    itemId: itemId,
                    callbackUrl: `${callbackUrl}bookconsultation/success`,
                },
                context: {
                    headers: {
                        Authorization: `Bearer ${authToken}`
                    }
                }
            });

            const reference = paymentUrlResponse?.data?.initPayment?.paymentInitResponse?.reference;
            const url = paymentUrlResponse?.data?.initPayment?.paymentInitResponse?.authorization_url;
            localStorage.setItem("psRef", reference);
            window.open(url, '_self');
            setAppLoading(false);
        } catch (error) {
            displayAlert("error", "Initiating consultation failed!");
            console.error("Failed to create consultation", error);
            setAppLoading(false);      
        }
    }
    
    return (
        <form className='flex flex-col h-full mt-6 gap-6' onSubmit={rescheduleConsultation}>
            {consultLoading && <Loader type='fullPage' />}
            <div>
                <p className='text-gray-400'>Date</p>
                <FormControl sx={{ marginTop: '5px', minWidth: 120 }}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DesktopDatePicker 
                            defaultValue={dayjs()} 
                            views={['day']}
                            disablePast
                            onChange={handlegetDoctors}
                        />
                    </LocalizationProvider>
                </FormControl>
            </div>
            
            <div>
                <p className='text-gray-400'>Time</p>
                <FormControl sx={{ marginTop: '5px', minWidth: 120 }}>
                    {/* <InputLabel id="demo-simple-select-helper-label">Select</InputLabel> */}
                    <Select
                        labelId="demo-simple-select-helper-label"
                        id="demo-simple-select-helper"
                        value={time}
                        onChange={(e) => setTime(e.target.value)}
                    >
                        {selectedSlot.length > 0?selectedSlot.map((t) => (
                            <MenuItem key={t._id} value={t.start}>{t.start} (GMT+1)</MenuItem>
                        )): <MenuItem>No available slot</MenuItem> }
                    </Select>
                </FormControl>
            </div>
            
            <div className="custom-fieldset">
                <p className='text-gray-400 '>Reason for follow-up</p>
                <FormControl sx={{ mt: '5px', minWidth: 120 }}>
                    <TextareaAutosize

                    id="outlined-basic" label="" variant="outlined"
                    value={reason} onChange={(e) => setReason(e.target.value)}
                        aria-label="minimum height"
                        minRows={3}
                        placeholder="I still don’t feel better"
                        style={{ width: '100%', fontSize: '16px', padding: '8px', outline: 'none', border:"1px solid #c4c4c4", borderRadius: "8px"
                    }}
                    />
                </FormControl>
            </div>

            <div className='flex-1 w-full flex items-end mb-10 gap-4'>
                <Button 
                    variant='outlined' 
                    onClick={() => setPref(false)}
                    className={classes.cancelButton}
                >
                    Cancel
                </Button>
                <Button 
                    variant='contained' 
                    className={classes.submitButton}
                    type='submit'
                >
                    {loading ? 
                        (<CircularProgress
                            style={{ width: 20, height: 20, color: "#fff" }}
                        /> )
                    : 
                        'Book Appointment'
                    }
                </Button>
            </div>
        </form>
    )
}
