import React from 'react'
import { useNavigate } from 'react-router';
import { ReactComponent as Star } from '../../assets/svgs/🦆 emoji _white medium star_.svg';
import { makeStyles } from '@mui/styles';
import { Button } from '@mui/material';
import { numberWithCommas } from '../../components/helperFunctions';
import { useQuery } from '@apollo/client';
import { DOCTOR_PROFILE } from '../../api/graphQL/query';
import { Skeleton } from '@mui/material';
import { useParams } from 'react-router-dom';


const useStyles = makeStyles(() => ({
    submitButton: {
        padding: "16px 24px !important",
        borderRadius: "8px !important",
        textTransform: "capitalize !important",
        width: '100% !important',
        borderColor: "#3E5EA9 !important",
        backgroundColor: "#3E5EA9 !important",
        fontSize: "16px !important",
        fontWeight: "500 !important",
        fontFamily: "Euclid Circular A !important",
    },
}));

export const DocDirect = () => {
    const classes = useStyles();
    const navigate = useNavigate();
    const docIdFromLS = JSON.parse(localStorage.getItem('doctor'))?.profile?._id || '';
    const { '*': docIdFromParam } = useParams();

    const docId = docIdFromLS || docIdFromParam

    const { data: doctorData, loading: doctorLoading } = useQuery(DOCTOR_PROFILE, {
        variables: { id: docId || "" },
    });
    if (doctorData) {
        localStorage.setItem('doctor', JSON.stringify(doctorData.doctorProfile));
    }
    const doc = doctorData?.doctorProfile?.profile;

    const loading = doctorLoading


    return (
        <div className='bg-secondary justify-center items-center w-full mid:h-screen h-full flex mid:flex-row flex-col mid:py-0 py-6 px-4'>
            <div className='bg-white rounded-[16px] heala-border w-full mid:w-[912px] mid:h-[auto]'>
                <div className='flex mid:flex-row flex-col w-full h-full'>
                    <div className='mid:w-[38%] w-full mid:h-full h-[300px] mid:rounded-none'>
                        {!loading && <img src={doc?.picture} alt='doc' className='mid:rounded-none heala-rounded w-full h-full object-fill' />}
                    </div>
                    <div className='heala-doc-profile h-full flex-1'>
                        <h5 className='mid:hidden font-medium mb-4'>Profile</h5>
                        {loading ? (
                            <Skeleton animation="wave" variant="text" width="60%" height={32} />
                        ) : (
                                <h3 className='mb-2 mid:text-[20px] mid:font-medium text-[#0C1322]'>Dr. {doc?.firstName} {doc?.lastName}</h3>
                        )}
                        {loading ? (
                            <Skeleton animation="wave" variant="text" width="60%" height={24} />
                        ) : (
                                <p className='mid:hidden mid:mb-2 text-[13px] mid:text-[14px] text-tertiary'>Heala ID : {doc?.dociId ? doc.dociId.substring(6) : ''}</p>
                        )}
                        {loading ? (
                            <Skeleton animation="wave" variant="text" width="40%" height={20} />
                        ) : (
                                <p className='mid:hidden mb-2 text-[11px] text-tertiary'>{doc?.specialization}</p>
                        )}
                        <div className='rounded-lg bg-secondary py-2 mid:px-4 px-2 heala-border flex items-center mid:w-[fit-content]  mb-4 mid:mb-0'>
                            <p className='text-[13px] mid:text-[14px]'>Consultation fee :</p>
                            {loading ? (
                                <Skeleton animation="wave" variant="text" height={24} width="36px" className='ml-[7px]' />
                            ) : (
                                    <p className='text-[16px] mid:text-[16px] text-primary font-medium ml-[7px]'>N{numberWithCommas(doc?.fee)}</p>
                            )}
                        </div>
                        {loading ? (
                            <div className='mid:hidden text-tertiary flex gap-1 items-center mb-4'>
                                <Skeleton animation="wave" variant="text" width={30} height={24} />
                                <div className='flex gap-1'>
                                    {[...Array(5)].map((_, index) => (
                                        <Skeleton key={index} animation="wave" variant="circular" width={16} height={16} />
                                    ))}
                                </div>
                            </div>
                        ) : (
                                <div className='mid:hidden text-tertiary flex gap-1 items-center mb-4'>
                                    <p className='text-[13px]'>{Number.isInteger(doc?.rating) ? `${doc?.rating}.0` : doc?.rating}</p>
                                    <div className='flex gap-1'>
                                        {[...Array(Math.floor(doc?.rating || 0))].map((_, index) => (
                                            <Star key={index} />
                                        ))}
                                    </div>
                                </div>
                        )}
                        <div className='mid:hidden text-[15px] block px-4 py-2 bg-secondary text-[#293F71] rounded-lg  border border-primary'>
                            <p>With 24/7 access to online doctors, care is always available, anytime and anywhere. Select and see your favorite providers again and again, right from your smartphone, tablet or computer</p>
                        </div>

                        <div className='mid:mt-5 mt-2 mid:mb-5'>
                            {loading ? (
                                <Skeleton animation="wave" variant="text" width="60%" height={24} />
                            ) : (
                                    <p className='hidden mid:block mb-2 text-[13px] text-tertiary'>Specialty: {doc?.specialization}</p>
                            )}
                            {loading ? (
                                <Skeleton animation="wave" variant="text" width="60%" height={24} />
                            ) : (
                                    <p className='hidden mid:block mid:mb-2 text-[13px] mid:text-[14px] text-tertiary'>Heala ID : {doc?.dociId ? doc.dociId.substring(6) : ''}</p>
                            )}
                            {loading ? (
                                <div className='text-tertiary text-[13px] hidden mid:flex gap-1 items-center'>
                                    <Skeleton animation="wave" variant="text" width={30} height={24} />
                                    <div className='flex gap-1'>
                                        {[...Array(5)].map((_, index) => (
                                            <Skeleton key={index} animation="wave" variant="circular" width={16} height={16} />
                                        ))}
                                    </div>
                                </div>
                            ) : (
                                    <div className='text-tertiary text-[13px] hidden mid:flex gap-1 items-center'>
                                        <p>{Number.isInteger(doc?.rating) ? `${doc?.rating}.0` : doc?.rating}</p>
                                        <div className='flex gap-1'>
                                            {[...Array(Math.floor(doc?.rating || 0))].map((_, index) => (
                                                <Star key={index} />
                                            ))}
                                        </div>
                                    </div>
                            )}
                        </div>

                        <div className='hidden text-[15px] mid:block px-6 py-4 mb-7 bg-secondary text-[#293F71] rounded-lg w-[478px] border border-primary'>
                            <p>With 24/7 access to online doctors, care is always available, anytime and anywhere. Select and see your favorite providers again and again, right from your smartphone, tablet or computer</p>
                        </div>

                        <div className='hidden mid:block w-full'>
                            <Button variant='contained' className={classes.submitButton} onClick={() => navigate('/doctor/form')}>Start Consultation</Button>
                            <p onClick={() => navigate('/access')} className='text-primary mt-4 text-center font-medium cursor-pointer'>View previous consultation</p>
                        </div>
                    </div>
                </div>
            </div>  

            <div className='my-10 mid:hidden block w-[359px]'>
                <Button variant='contained' className={classes.submitButton} onClick={() => navigate('/doctor/form')} sx={{height:'44px'}}>Start Consultation</Button>
                <p onClick={() => navigate('/access')} className='text-primary rounded-lg mt-4 text-center font-medium cursor-pointer'>View Previous consultation</p>
            </div>      
        </div>
    )
}
