import React from 'react'

export default function Menubox({ children, onClicked, activeItem, setMenu, disabled }) {
    
    const handleClick = () => {
        if (disabled) return;

        onClicked();
        setMenu(false);
    };
  
    return (
        <div
            className={
                `flex gap-4 p-4 cursor-pointer ${activeItem ? 'leftbox-clicked' : ''} ${disabled ? 'opacity-50 cursor-not-allowed text-gray-400' : ''}`
            }
            onClick={handleClick}
        >
            {children}
        </div>
    );
}
