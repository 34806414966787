import { gql } from "@apollo/client";

export const GET_PARTNER_BY_SUBDOMAIN = gql`
  query getPartnerBySubdomain($subdomain: String!) {
    getPartnerBySubdomain(data: { subdomain: $subdomain }) {
      subdomain
      providerId
      widgetColor
      widgetLogo
      apiKey
      category
    }
  }
`;

export const GET_ISSUES = gql`
  query getIssues {
    getIssues {
      issues {
        ID
        Name
      }
    }
  }
`;

export const GET_SERVER_TIME = gql`
  query getServerTime {
    getServerTime {
      time
      timeLagos
      timeJSdate
      timeLagosJsDate
      hourMin
      hourMinLagos
    }
  }
`;

export const RESET_PASSWORD = gql`
  mutation resetPassword($email: EmailAddress!) {
    resetPassword(data: { email: $email }) {
      account {
        _id
        email
        dociId
        createdAt
        updatedAt
        isEmailVerified
        isPasswordTemporary
        otpTokenExpiresAt
      }
      errors {
        field
        message
      }
    }
  }
`;

export const SENDOTP = gql`
  mutation sendOTP($email: String!) {
    sendOTP(data: { email: $email })
  }
`;

export const COMPLETE_RESET_PASSWORD = gql`
  mutation completePasswordReset(
    $email: EmailAddress!
    $newPassword: String!
    $token: Int!
  ) {
    completePasswordReset(
      data: { email: $email, newPassword: $newPassword, token: $token }
    ) {
      account {
        _id
        email
        dociId
        createdAt
        updatedAt
        isEmailVerified
        isPasswordTemporary
      }
      errors {
        field
        message
      }
    }
  }
`;
export const LOGIN = gql`
  mutation login(
    $email: EmailAddress!
    $password: String!
    $authType: String!
  ) {
    login(data: { email: $email, password: $password, authType: $authType }) {
      account {
        _id
        dociId
        email
        isEmailVerified
        access_token
        refresh_token
        nextStep
        deactivated
        deactivatedAt
        deactivateType
        isPasswordTemporary
      }
      errors {
        field
        message
      }
    }
  }
`;

export const COMPLETE_PASSWORD_RESET = gql`
  mutation completePasswordReset(
    $email: EmailAddress!
    $otp: Int!
    $password: String!
  ) {
    completePasswordReset(
      data: { email: $email, newPassword: $password, token: $otp }
    ) {
      account {
        _id
        email
        dociId
        createdAt
        updatedAt
        isEmailVerified
        isPasswordTemporary
      }
      errors {
        field
        message
      }
    }
  }
`;

export const GET_USER_TYPES = gql`
  query getUserTypes {
    getUserTypes {
      userType {
        _id
        name
        icon
        description
        providerCount
      }
    }
  }
`;
export const SIGNUP = gql`
  mutation signup(
    $email: EmailAddress!
    $password: String!
    $role: String!
    $providerId: String!
    $userTypeId: String!
  ) {
    signup(
      data: {
        authType: "normal"
        email: $email
        password: $password
        role: $role
        providerId: $providerId
        userTypeId: $userTypeId
      }
    ) {
      account {
        _id
        email
        dociId
        createdAt
        updatedAt
        isEmailVerified
        access_token
        role
        providerId
        userTypeId
        enrolleeNumber
        isPasswordTemporary
      }
      message
      errors {
        field
        message
      }
    }
  }
`;

export const CREATE_PROFILE = gql`
  mutation createProfile(
    $firstName: String!
    $lastName: String!
    $gender: String!
    $dociId: String!
    $phoneNumber: String!
    $providerId: String!
  ) {
    createProfile(
      data: {
        firstName: $firstName
        lastName: $lastName
        gender: $gender
        phoneNumber: $phoneNumber
        dociId: $dociId
        providerId: $providerId
      }
    ) {
      profile {
        firstName
        lastName
        phoneNumber
        gender
        providerId
      }
      errors {
        field
        message
      }
    }
  }
`;

export const VERIFY_EMAIL = gql`
  mutation verifyEmail($email: String!, $otp: String!) {
    verifyEmail(data: { email: $email, otp: $otp }) {
      message
      account {
        access_token
        _id
        email
        dociId
        createdAt
        updatedAt
        isEmailVerified
        isPasswordTemporary
      }
    }
  }
`;
export const RESEND_OTP = gql`
  mutation resendOTP($email: EmailAddress!) {
    resendOTP(data: { email: $email }) {
      account {
        _id
        email
        dociId
        createdAt
        updatedAt
        isEmailVerified
        isPasswordTemporary
        otpTokenExpiresAt
      }
      errors {
        field
        message
      }
    }
  }
`;

export const DOCTOR_PROFILE = gql`
  query doctorProfile($id: String!) {
    doctorProfile(data: { id: $id }) {
      profile {
        _id
        firstName
        lastName
        gender
        phoneNumber
        createdAt
        updatedAt
        email
        fee
        hospital
        specialization
        dob
        cadre
        picture
        providerId {
          _id
          name
        }
        #consultations
        status
        dociId
        rating
        accountDetails {
          accountName
          accountNumber
          bankName
        }
      }
    }
  }
`;

export const USER_PROFILES = gql`
  query userProfiles($email: String!) {
    profiles(filterBy: {email: $email}) {
    data {
      _id
      firstName
      lastName
      email
      height
      weight
      bloodGroup
      genotype
      gender
      phoneNumber
      plan
      status
      consultations
      createdAt
      image
      rating
      accountId {
        _id
      }

    }
  }
  }
`;

export const CREATE_CONSULTATIONS = gql`
  mutation createConsultation(
    $time: String!
    $symptoms: [Symptom!]!
    $contactMedium: String!
    $providerId: String!
    $firstNotice: String!
    $description: String!
    $patient: String!
    $doctor: String!
    $discomfortLevel: String!
    $createdThrough: String!
  ) {
    createConsultation(
      data: {
        consultationOwner: "myself"
        patient: $patient
        doctor: $doctor
        symptoms: $symptoms
        discomfortLevel: $discomfortLevel
        description: $description
        firstNotice: $firstNotice
        type: "scheduled"
        time: $time
        status: "pending"
        providerId: $providerId
        contactMedium: $contactMedium
        createdThrough: $createdThrough
      }
    ) {
      consultation {
        _id
        patient
        consultationOwner
        symptoms {
          name
        }
        description
        discomfortLevel
        firstNotice
        createdThrough
        createdAt
        time
        type
        appointmentAcceptedAt
        appointmentStartedAt
        trackingId
        notificationSchedules {
          _id
          time
          notified
          consultationId
        }
        updatedAt
        contactMedium
        providerId
      }
      errors {
        field
        message
      }
    }
  }
`;
export const GET_LATEST_CONSULTATIONS = gql`
  query getConsultations($id: String!, $type: String!, $paid: String) {
    getConsultations(
      filterBy: { patient: $id, type: $type, paid: $paid }
      orderBy: "-createdAt"
      first: 3
    ) {
      data {
      _id
        time
      doctor {
        picture 
        firstName
        lastName
        rating
      }
      fee
      contactMedium
      status
      _id
      }
    }
  }
`;

export const GET_CONSULT = gql`
  query getConsultation($id: ID!) {
    getConsultation(id: $id) {
      _id
      patient
      consultationOwner
      symptoms {
        name
      }
      description
      discomfortLevel
      firstNotice
      doctor
      doctorData
      patientData
      providerId
      declineReason
      consultationDuration
      diagnosis {
        ailment
        severity
      }
      doctorNote
      createdAt
      updatedAt
      referralId
      prescription {
        _id
        drugs {
          priceListId
          drugName
          drugPrice
          unitPrice
          dosageQuantity
          dosageUnit
          route
          amount
          instructions
          dosageFrequency {
            timing
            duration
          }
        }
      }
    }
  }
`;

export const GET_REFERRALS = gql`
  query getReferrals($filterBy: JSONObject, $page: Int) {
    getReferrals(filterBy: $filterBy, page: $page) {
      referral {
        _id
        doctor
        patient
        type
        reason
        note
        specialization
        provisionalDiagnosis
        urgency
        tests {
          name
          price
          tat
        }
        consultationId
        createdAt
        updatedAt
        doctorData
        patientData
      }
      pageInfo {
        totalDocs
        limit
        offset
        hasPrevPage
        hasNextPage
        page
        totalPages
        pagingCounter
        prevPage
        nextPage
      }
    }
  }
`;
export const GET_CONSULTATIONS = gql`
  query getConsultations($filterBy: JSON, $page: Int) {
    getConsultations(filterBy: $filterBy, orderBy: "-createdAt", page: $page) {
      data {
        _id
        patient {
          _id
          firstName
          lastName
        }
        doctor {
          _id
          firstName
          lastName
          rating
        }
        consultationOwner
        symptoms {
          name
        }
        description
        discomfortLevel
        firstNotice
        paid
        doctor
        consultationDuration
        diagnosis {
          ailment
          severity
        }
        doctorNote
        declineReason
        createdAt
        updatedAt
        referralId
        providerId
        contactMedium
        status
        fee
        time
      }
      pageInfo {
        totalDocs
        limit
        offset
        hasPrevPage
        hasNextPage
        page
        totalPages
        pagingCounter
        prevPage
        nextPage
      }
    }
  }
`;

export const GET_TESTS = gql`
  query getDiagnosticTests($filterBy: JSONObject, $page: Int) {
    getDiagnosticTests(filterBy: $filterBy, page: $page) {
      data {
        _id
        partner
        patient
        doctor
        reason
        referralId
        doctorData
        note
        status
        sampleCollection
        testResults
        cancellationReason
        partnerData
        updatedAt
        scheduledAt
      }
      pageInfo {
        totalDocs
        limit
        offset
        hasPrevPage
        hasNextPage
        page
        totalPages
        pagingCounter
        prevPage
        nextPage
      }
    }
  }
`;

export const GET_PRESCRIPTIONS = gql`
  query getPrescriptions($id: String!) {
    getPrescriptions(filterBy: { patient: $id }, page: 1) {
      data {
        _id
        doctor
        patient
        doctorData
        patientData
        consultation
        drugs {
          priceListId
          drugName
          drugPrice
          unitPrice
          dosageQuantity
          dosageUnit
          route
          amount
          instructions
          dosageFrequency {
            timing
            duration
          }
        }
      }
      pageInfo {
        totalDocs
        limit
        offset
        hasPrevPage
        hasNextPage
        page
        totalPages
        pagingCounter
        prevPage
        nextPage
      }
    }
  }
`;

export const RESCHEDULE_CONSULTATION = gql`
  mutation rescheduleConsultation(
    $id: ID!
    $time: String!
    $doctor: String!
    $reason: String!
  ) {
    rescheduleConsultation(
      data: { id: $id, time: $time, doctor: $doctor, reason: $reason }
    ) {
      consultation {
        _id
        patient
        consultationOwner
        time
        type
        appointmentAcceptedAt
        appointmentStartedAt
        trackingId
        notificationSchedules {
          _id
          time
          notified
          consultationId
        }
        symptoms {
          name
        }
        description
        discomfortLevel
        firstNotice
        doctor
        diagnosis {
          ailment
          severity
        }
        doctorNote
        declineReason
        providerId
        createdAt
        updatedAt
        time
      }
      errors {
        field
        message
      }
    }
  }
`;

export const UPDATE_PROFILE = gql`
  mutation updateProfile(
    $id: String!
    $image: String!
    $height: Int
    $weight: Int
    $bloodGroup: String!
    $genotype: String!
    $firstName: String!
    $lastName: String!
    $gender: String!
    $phoneNumber: String!
  ) {
    updateProfile(
      data: {
        id: $id
        firstName: $firstName
        lastName: $lastName
        gender: $gender
        phoneNumber: $phoneNumber
        image: $image
        height: $height
        weight: $weight
        bloodGroup: $bloodGroup
        genotype: $genotype
      }
    ) {
      profile {
        _id
        firstName
        lastName
        height
        weight
        bloodGroup
        genotype
        gender
        phoneNumber
        image
      }
      errors {
        field
        message
      }
    }
  }
`;

export const CHECK_PAYMENT = gql`
  query checkCharge($reference: String!) {
    checkCharge(data: {reference: $reference}) {
      chargeResponse {
        id
        status
        reference
        amount
        message
        gateway_response
        paid_at
        channel
        currency
        authorization {
          authorization_code
          bin
          last4
          exp_month
          exp_year
          channel
          card_type
          bank
          country_code
          brand
          reusable
          signature
          account_name
        }
      }
    }
  }
`;

export const GET_PLANS = gql`
  query getPlans($id: String!) {
    getPlans(user: $id) {
      plan {
        _id
        name
        amount
        description
        createdAt
        updatedAt
        provider
        providerData
        duration
        subscribed
      }
    }
  }
`;

export const GET_DOCTOR_AVAILIBILITY_FOR_DATE = gql`
  query getDoctorAvailabilityForDate($id: String!, $date: String!) {
    getDoctorAvailabilityForDate(doctorId: $id, date: $date) {
      day
      available
      times {
        _id
        start
        stop
        available
      }
    }
  }
`;

export const CREATE_PAYMENT_URL = gql`
  mutation initPayment(
    $user: String!
    $amount: String!
    $email: String!
    $itemId: String!
    $callbackUrl: String!
  ) {
    initPayment(
      data: {
        user: $user
        reason: "consultation"
        amount: $amount
        email: $email
        itemId: $itemId
        callbackUrl: $callbackUrl
        saveCard: true
      }
    ) {
      paymentInitResponse {
        authorization_url
        reference
      }
      errors {
        field
        message
      }
    }
  }
`;

export const GET_AVAILABLE_DOCTORS_BY_DATE = gql`
  query getAvailableDoctors($date: String!, $id: String!) {
    getAvailableDoctors(filterBy: { providerId: $id }, date: $date) {
      profile {
        _id
        firstName
        lastName
        gender
        phoneNumber
        createdAt
        updatedAt
        email
        email
        hospital
        specialization
        dob
        cadre
        picture
        provider
        consultations
        status
        dociId
        rating
        providerId
        accountDetails {
          accountName
          accountNumber
          bankName
        }
      }
    }
  }
`;

export const GET_DASHBOARD_OVERVIEW_COUNTS = gql`
  query GetDashboardOverviewCounts($patientId: String!) {
  getConsultations(filterBy: {patient: $patientId}) {
    pageInfo {
      totalDocs
    }
  }
  getReferrals(filterBy: {patient: $patientId}) {
    pageInfo {
      totalDocs
    }
  }
  getPrescriptions(filterBy: {patient: $patientId}) {
    pageInfo {
      totalDocs
    }
  }
}
`