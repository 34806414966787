import React, { useState, useEffect, useContext, useCallback } from 'react'
import { useQuery } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as Arrow } from '../../assets/svgs/arrow.svg';
import { ReactComponent as Star } from '../../assets/svgs/🦆 emoji _white medium star_.svg';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { Input } from '../../components/Input';
import { CircularProgress } from "@mui/material";
import { Button } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { getAvailableTimeSlot as getAvailableTimeSlots, convertToISOString, numberWithCommas } from '../../components/helperFunctions';
import { GET_SERVER_TIME } from '../../api/graphQL/query';
import { useFetchAPI } from '../../api/axios/axios';
import useAlert from '../../hooks/useAlert';
import { TextField } from "@mui/material";
import { DesktopDatePicker, MobileDatePicker } from "@mui/x-date-pickers";

import { AppContext } from '../../App';
import { safeGetLocalStorageJSON } from '../../libs/helperFuncs';
import axios from 'axios';

const useStyles = makeStyles(() => ({
    submitButton: {
        marginTop: "18px !important",
        padding: "16px 24px !important",
        borderRadius: "8px !important",
        textTransform: "capitalize !important",
        backgroundColor: "#3E5EA9 !important",
        fontSize: "13px !important",
        fontWeight: "500 !important",
        fontFamily: "Euclid Circular A !important",
        width: '100%',

        '@media (max-width: 400px)': {
            padding: '16px 14px !important',
            height: '44px'
        },
    },
}));

export const DocPreference = () => {
    const classes = useStyles();
    const { displayAlert } = useAlert();
    const { GET_DOCTOR_AVAILIBILITY_FOR_DATE, VALIDATE_EMAIL, CREATE_CONSULTATION, CREATE_PAYMENT_URL } = useFetchAPI();
    const { loading: appLoading, setLoading: setAppLoading } = useContext(AppContext);


    const docData = safeGetLocalStorageJSON('doctor');
    const docProfile = docData.profile;

    console.log(docData.profile, 'doc');
    const form2 = safeGetLocalStorageJSON('formData');

    const providerId = localStorage.getItem("providerId");

    const localProfile = safeGetLocalStorageJSON('profile')

    const navigate = useNavigate();
    const consultInfo = safeGetLocalStorageJSON('form1Data');
    const [loading, setLoading] = useState(false);
    const [email, setEmail] = useState(form2?.email || '');
    const [date, setDate] = useState(dayjs(form2?.date || undefined));
    const [time, setTime] = useState(form2?.time || '');
    const [channel, setChannel] = useState(form2?.channel || '');
    const [emailError, setEmailError] = useState('');
    const [timeError, setTimeError] = useState('');
    const [channelError, setChannelError] = useState('');
    const [doctorTimes, setDoctorTimes] = useState([]);
    const [profile, setProfile] = useState({});
    const docId = docProfile?._id;
    const callbackUrl = process.env.REACT_APP_FRONTEND_URL;

    const getDoctorsTimesByDate = useCallback(async (date, docId) => {
        const formattedDate = date.format('YYYY-MM-DD');
        try {
            const res = await GET_DOCTOR_AVAILIBILITY_FOR_DATE(formattedDate, docId);
            const result = res?.data?.data?.times || [];
            return result
        } catch (error) {
            displayAlert("error", "Error while trying to get available times.");
            console.error("Error from getDoctorsTimesByDate function:", error);
            return [];
        }
    }, [GET_DOCTOR_AVAILIBILITY_FOR_DATE])

    // get doctor availability time whenever date changes
    useEffect(() => {
        async function fetchData() {
            if (docId) {
                const doctorsTimes = await getDoctorsTimesByDate(date, docId);
                setDoctorTimes(doctorsTimes);
            }
        }
        fetchData();
    }, [date, docId, getDoctorsTimesByDate]);

    useEffect(() => {
        if (Object.keys(profile).length > 0) {
            createConsultation();
        }
    }, [profile]);

    const handleNext = () => {
        if (!email) {
            setEmailError('This field is required!');
        }
        if (!time) {
            setTimeError('This field is required!');
        }
        if (!channel) {
            setChannelError('This field is required!');
        }

        if (!email || !time || !channel) {
            return;
        }

        const form1Data = safeGetLocalStorageJSON('form1Data')

        const form2Data = {
            email,
            date,
            time: getISOStringDateTime(time, date),
            channel,
        };

        const formData = {
            ...form1Data,
            ...form2Data,
        };
        localStorage.setItem('formData', JSON.stringify(formData));

        handleVerification(email);
    }

    const handleVerification = async (email) => {
        try {
            setLoading(true);
            const data = {
                email: email
            }
            const res = await VALIDATE_EMAIL(data);
            if (res?.data?.statusCode === 201) {

                localStorage.setItem('profile', JSON.stringify(res?.data?.data?.data));
                setProfile(res?.data?.data);
            } else {
                navigate('/doctor/information');
            }
        } catch (error) {
            navigate('/doctor/information');
        }
        setLoading(false);
    }

    const { error: serErr, data: serData, loading: serLoad } = useQuery(GET_SERVER_TIME);

    const serverTimeJSDateString = serData?.getServerTime?.timeLagosJsDate;

    const currentDateL = serverTimeJSDateString?.split('').splice(0, 15).join('');
    const currentDate = serverTimeJSDateString
    var dateComponents = date.format('YYYY-MM-DD').split("-");

    var year = dateComponents[0];
    var month = parseInt(dateComponents[1]) - 1; // JavaScript uses zero-based months
    var day = dateComponents[2];

    var months = [
        "Jan", "Feb", "Mar", "Apr", "May", "Jun",
        "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
    ];

    var dateObj = new Date(year, month, day);
    var formattedDate = dateObj.toString().substr(0, 3) + " " + months[month] + " " + day + " " + year;
    console.log(currentDateL, "currentDatexyz", formattedDate, currentDate)

    const availableTimes = getAvailableTimeSlots(doctorTimes, serverTimeJSDateString)

    const createConsultation = async () => {
        const ISOStringDateTime = getISOStringDateTime(time, date);

        try {
            setLoading(true);
            setAppLoading(true);
            const consultationVar = {
                consultationOwner: 'myself',
                patientData: {
                    email: consultInfo?.email || email,
                    firstName: profile?.firstName || localProfile?.firstName,
                    lastName: profile?.lastName || localProfile?.lastName,
                    phoneNumber: profile?.phoneNumber || localProfile?.phoneNumber,
                    gender: String(profile?.gender || localProfile?.gender || '').toLowerCase(),
                },
                doctor: docProfile._id,
                discomfortLevel: consultInfo?.level,
                description: consultInfo?.aob || undefined,
                firstNotice: consultInfo?.expect,
                contactMedium: channel || consultInfo?.channel,
                time: ISOStringDateTime,
                symptoms: consultInfo?.batches?.length > 0
                    ? consultInfo?.batches.map((batch) => ({
                        name: batch,
                    }))
                    : [{ name: consultInfo?.symptoms }],
                type: "scheduled",
                providerId: providerId,
                timeOffset: "0.00",
                status: "pending",
                createdThrough: "doctor-direct"
            }
            const res = await CREATE_CONSULTATION(consultationVar);
            localStorage.setItem("consultationInfo", JSON.stringify(res?.data?.data));

            setLoading(false);
            const paymentUrlResponse = await CREATE_PAYMENT_URL({
                email: consultInfo?.email || email,
                reason: "consultation",
                itemId: res?.data?.data?._id,
                callback_url: `${callbackUrl}doctors/consultsuccess`,
                amount: docProfile?.fee
            });
            const paymentInitResponse = paymentUrlResponse?.data?.data.paymentInitResponse
            const reference = paymentInitResponse?.reference;
            const url = paymentInitResponse?.authorization_url;
            localStorage.setItem("psRef", reference);
            window.open(url, '_self');
            setAppLoading(false);
            localStorage.setItem("form1Data", JSON.stringify({}))
            localStorage.setItem("formData", JSON.stringify({}))
            localStorage.setItem("profile", JSON.stringify({}))

        } catch (error) {
            let errorMessage = "";

            if (axios.isAxiosError(error)) {
                errorMessage = error.response?.data?.message
            }

            displayAlert("error", errorMessage || "Initiating consultation failed!");
            console.error("Failed to create consultation", error);
            setAppLoading(false);
            setLoading(false);
        }
    }

    return (
        <div className='bg-secondary mid:justify-center justify-start mid:items-center w-full mid:h-screen min-h-screen flex mid:flex-row flex-col px-[18px] mid:px-0'>
            <Arrow className='mid:hidden block my-[19px] cursor-pointer w-6 h-6' onClick={() => navigate('/doctor/form')} />
            <div className='bg-white mid:rounded-[16px] heala-border w-full mid:w-[912px] h-full mid:h-[auto]'>
                <div className='flex w-full h-full '>
                    <div className='hidden mid:block w-[31%] h-full border-r border-[#CAC4D0] px-4'>
                        <Arrow className='my-[19px] cursor-pointer' onClick={() => navigate('/doctor/form')} />
                        <img src={docProfile?.picture} alt='doc' className='w-[256px] h-[260px] rounded-lg mid:mb-0 mb-4' />
                        <div className='pt-4 pb-6 px-5 flex flex-col gap-1'>
                            <h5 className='text-[16px] font-medium text-[#0C1322]'>Profile </h5>
                            <p className='text-[#0C1322] text-[16px]'>Dr. {docProfile?.firstName} {docProfile?.lastName}</p>
                            <p className='text-[11px] text-tertiary'>{docProfile?.specialization}</p>
                            <div className='text-tertiary flex gap-1 items-center'>
                                <p className='text-[13px]'>{Number.isInteger(docProfile?.rating) ? `${docProfile?.rating}.0` : docProfile?.rating}</p>
                                <div className='flex gap-1'>
                                    {[...Array(Math.floor(docProfile?.rating || 0))].map((_, index) => (
                                        <Star key={index} />
                                    ))}
                                </div>
                            </div>
                            <div className='rounded-lg bg-secondary p-2 heala-border flex items-center'>
                                <p className='text-[13px]'>Consultation fee :</p>
                                <p className='text-[16px] text-primary font-medium ml-[7px]'>N{numberWithCommas(docProfile?.fee)}</p>
                            </div>
                            <p className='text-tertiary text-[13px]'>Heala ID : {docProfile?.dociId ? docProfile.dociId.substring(6) : ''}</p>
                        </div>
                    </div>

                    <div className='flex flex-col gap-[5px] flex-1 py-6 px-4 mid:px-11 mid:py-[19px]'>
                        <div className='mb-[3px]'>
                            <h3 className='text-[20px] font-medium'>Booking Preference</h3>
                            <p className='text-[13px]'>Pick a suitable time and communication channel for your consultation</p>
                        </div>

                        <div>
                            <h3 className="text-[#0C1322] mb-1 text-[13px]">Select a date</h3>
                            <FormControl sx={{ mb: 1, minWidth: 120 }} >
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    {/* <DatePicker
                                        sx={{width: '100%'}}
                                        open={isOpen}
                                        onOpen={handleOpen}
                                        onClose={handleClose}
                                        defaultValue={dayjs()}
                                        views={['day']}
                                        disablePast
                                        onChange={handlegetDoctors}
                                    /> */}
                                    <DesktopDatePicker
                                        onChange={setDate}
                                        value={date}
                                        views={['day', 'month', 'year']}
                                        disablePast
                                        style={{ height: "5rem !important" }}
                                    />
                                </LocalizationProvider>
                            </FormControl>
                        </div>

                        <div>
                            <h3 className="text-[#0C1322] mb-1 text-[13px]">Select a time</h3>
                            <FormControl sx={{ mb: 1, minWidth: 120 }}>
                                <Select
                                    labelId="demo-simple-select-helper-label"
                                    value={time}
                                    onChange={(e) => {
                                        setTime(e.target.value)
                                        setTimeError('')
                                    }
                                }
                                >
                                    {availableTimes.length > 0 ? availableTimes.map((t) => (
                                        <MenuItem key={t._id} value={t.start}>{t.start} (GMT+1)</MenuItem>
                                    )) : <MenuItem>No available slot</MenuItem>}
                                </Select>
                            </FormControl>
                            {timeError && <p className='text-error'>{timeError}</p>}
                        </div>

                        <div>
                            <h3 className="text-[#0C1322] mb-1 text-[13px]">Communication Channel</h3>
                            <FormControl sx={{ mb: 1, minWidth: 120 }}>
                                <Select
                                    className={classes.error}
                                    labelId="demo-simple-select-helper-label"
                                    id="demo-simple-select-helper"
                                    value={channel}
                                    onChange={(e) => {
                                        setChannel(e.target.value)
                                        setChannelError('')
                                    }
                                    }
                                >
                                    <MenuItem value="chat">Chat</MenuItem>
                                    <MenuItem value="video">Video</MenuItem>
                                    <MenuItem value="voice">Voice</MenuItem>
                                </Select>
                            </FormControl>
                            {channelError && <p className='text-error'>{channelError}</p>}
                        </div>

                        <div className='mb-10 mid:mb-0'>
                            <h3 className="text-[#0C1322] mb-1 text-[13px]">Email Address</h3>
                            <Input
                                type="email"
                                placeholder="Email Address"
                                fullWidth
                                value={email}
                                onChange={(e) => {
                                    setEmail(e.target.value)
                                    setEmailError('')
                                }
                                }
                                style={{ marginBottom: '5px' }}
                            />
                            {emailError && <p className='text-error'>{emailError}</p>}
                        </div>

                        <Button
                            variant='contained'
                            onClick={handleNext}
                            className={classes.submitButton}
                        >
                            {loading ?
                                (<CircularProgress
                                    style={{ width: 20, height: 20, color: "#fff" }}
                                />)
                                : 'Next'}
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    )
}

function getISOStringDateTime(timeString, dayJSDate) {
    const [hours, minutes] = timeString.split(':');
    const fullDate = dayJSDate.set('hours', hours).set('minutes', minutes);
    const ISOStringDateTime = fullDate.toISOString()
    return ISOStringDateTime;
}