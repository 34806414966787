import React from 'react'
import { useNavigate } from 'react-router-dom';
import { Button, Skeleton } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { ReactComponent as Dash1 } from '../../assets/svgs/dash.svg'
import { ReactComponent as Dash2 } from '../../assets/svgs/dash2.svg'
import { ReactComponent as Dash3 } from '../../assets/svgs/dash3.svg'
import { ReactComponent as Menus } from '../../assets/svgs/menu.svg';
import { ReactComponent as Empty } from '../../assets/svgs/empty.svg';
import Drawers from '../drawers';
import Menu from '../menu/menu';
import { useQuery } from '@apollo/client';
import { GET_DASHBOARD_OVERVIEW_COUNTS, GET_LATEST_CONSULTATIONS } from '../../api/graphQL/query';
import { safeGetLocalStorageJSON } from '../../libs/helperFuncs';
import ConsultationCard from '../ConsultationCard';

const useStyles = makeStyles(() => ({
  Button: {
    padding: "16px 24px !important",
    borderRadius: "8px !important",
    textTransform: "capitalize !important",
    backgroundColor: "#CED0D3 !important",
    fontSize: "13px !important",
    fontWeight: "500 !important",
    fontFamily: "Euclid Circular A !important",
    width: '100%',
    color: '#5D626C !important',
    boxShadow: 'none !important',
    transition: '.2s ease-out',
    '&:hover': {
      backgroundColor: '#3E5EA9 !important',
      color: '#EDEDED !important',
      opacity: 0.75
    },
  },
  submitButton: {
    padding: "16px 24px !important",
    borderRadius: "8px !important",
    textTransform: "capitalize !important",
    backgroundColor: "#3E5EA9 !important",
    fontFamily:"Euclid Circular A !important",
    fontSize: "13px !important",
    fontWeight: "500 !important",
    width: '60%',
    color: '#FFFFFF !important',
    marginTop: '24px !important'
  },
}));


export default function Board({ setMenu, menu, activeItem, setActiveItem, openProfile, setOpenProfile }) {
  const classes = useStyles();
  const navigate = useNavigate();
  const patientId = safeGetLocalStorageJSON("profile")?._id;

  const { data: overviewCounts, loading: overviewCountsLoading } = useQuery(GET_DASHBOARD_OVERVIEW_COUNTS, {
    variables: {
      patientId
    }
  })
  const totalConsultations = overviewCounts?.getConsultations?.pageInfo?.totalDocs ?? 'N/A';
  const totalPrescriptions = overviewCounts?.getPrescriptions?.pageInfo?.totalDocs ?? 'N/A';
  const totalTests = overviewCounts?.getReferrals?.pageInfo?.totalDocs ?? 'N/A';

  const { loading: latestConsultationsLoading, data: latestConsultations } = useQuery(GET_LATEST_CONSULTATIONS, {
    variables: {
      id: patientId, type: "scheduled",
    }
  })
  const scheduledConsultations = latestConsultations?.getConsultations?.data;

  const isLoading = overviewCountsLoading || latestConsultationsLoading;

  return (
    <>
      {scheduledConsultations?.length === 0 ? (
        <div className='flex justify-center h-full w-full'>
          <div className='flex flex-col items-center justify-center h-[91%] w-2/5'>
            <Empty />
            <h3 className='-mt-6 text-[25px] font-medium font-euclid text-center'>Schedule your first Consultation</h3>
            <p className='text-center font-euclid text-gray-400'>You can schedule a consultation by clicking the button below</p>
            <Button className={classes.submitButton} onClick={() => navigate('/bookconsultation')}>Schedule Consultation</Button>
          </div>
        </div>
      ) : (        
        <>
          <div className='flex flex-col min-h-screen mb-10'>
            <div className='flex items-center gap-4'>
              <Menus className='desktop:hidden' onClick={() => setMenu(!menu)} />
              <h3 className='font-medium text-[25px]'>Dashboard</h3>
            </div>
            <div className='mt-6'>
              <div className='flex flex-col md:flex-row gap-4 w-full'>
                  {isLoading ? (
                  <Skeleton variant="rounded" width='100%' height={130} animation="wave"/>
                ) : (
                  <div className='w-full h-[130px] flex rounded-lg border border-gray-50'>
                    <div className='w-[141px] flex gap-4'>
                      <div className='w-[10px] h-full bg-status-300 rounded-l-lg'></div>
                      <div className='self-center'>
                            <h3 className='font-medium text-[25px]'>{totalConsultations}</h3>
                        <p className='mt-2'>Consultations</p>
                      </div>
                    </div>
                    <div className='flex-1 flex justify-end pr-4 items-center'><Dash1 /></div>
                  </div>
                )}
                
                  {isLoading ? (
                  <Skeleton variant="rounded" width='100%' height={130} animation="wave"/>
                ) : (
                  <div className='w-full h-[130px] flex rounded-lg border border-gray-50'>
                    <div className='w-[141px] flex gap-4'>
                      <div className='w-[10px] h-full bg-status-100 rounded-l-lg'></div>
                      <div className='self-center'>
                            <h3 className='font-medium text-[25px]'>{totalPrescriptions}</h3>
                        <p className='mt-2'>Prescriptions</p>
                      </div>
                    </div>
                    <div className='flex-1 flex justify-end pr-4 items-center'><Dash2 /></div>
                  </div>
                )}
                
                  {isLoading ? (
                  <Skeleton variant="rounded" width='100%' height={130} animation="wave"/>
                ) : (
                  <div className='w-full h-[130px] flex rounded-lg border border-gray-50'>
                    <div className='w-[141px] flex gap-4'>
                      <div className='w-[10px] h-full bg-status-400 rounded-l-lg'></div>
                      <div className='self-center'>
                            <h3 className='font-medium text-[25px]'>{totalTests}</h3>
                        <p className='mt-2'>Tests</p>
                      </div>
                    </div>
                    <div className='flex-1 flex justify-end pr-4 items-center'><Dash3 /></div>
                  </div>
                )}
              </div>
            </div>
            
            <div className='mt-6 flex md:grid md:grid-cols-2 mids:grid-cols-3 flex-wrap justify-between gap-5'>
                {isLoading ? (
                <>
                  <Skeleton variant="rounded" width='100%' height={430} animation="wave" />
                  <Skeleton variant="rounded" width='100%' height={430} animation="wave"/>
                  <Skeleton variant="rounded" width='100%' height={430} animation="wave"/>
                </>
              ) : (
                    scheduledConsultations?.map((consultation, index) => (
                      <ConsultationCard
                        key={index}
                        consultation={consultation}
                        classes={classes}
                      />
                    ))
              )}
            </div>
          </div>
        
        </>
      )}
      <Drawers openDrawer={menu} setOpenDrawer={setMenu} side="left" width='298px' minwidth='298px'>
        <div className='px-8 pt-10 h-full'>
          <Menu activeItem={activeItem} setActiveItem={setActiveItem} setMenu={setMenu} menu={menu} openProfile={openProfile} setOpenProfile={setOpenProfile}/>
        </div>
      </Drawers>
    </>
  )
}
