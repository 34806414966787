import React, { useState, useContext } from 'react'
import { useNavigate } from 'react-router-dom';
import { ReactComponent as Arrow } from '../../assets/svgs/arrow.svg';
import { ReactComponent as Star } from '../../assets/svgs/🦆 emoji _white medium star_.svg';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import { Input } from '../../components/Input';
import { CircularProgress } from "@mui/material";
import { Button } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useFetchAPI } from '../../api/axios/axios';
import useAlert from '../../hooks/useAlert';
import { PhoneDropdown } from '../../components/phone';
import { convertToISOString, numberWithCommas } from '../../components/helperFunctions';
import { AppContext } from '../../App';
import { ProfileContext } from '../../context/profileContext';
import { safeGetLocalStorageJSON } from '../../libs/helperFuncs';

const useStyles = makeStyles(() => ({
    submitButton: {
        marginTop:"18px !important",
        padding: "16px 24px !important",
        borderRadius: "8px !important",
        textTransform: "capitalize !important",
        backgroundColor: "#3E5EA9 !important",
        fontSize: "13px !important",
        fontWeight: "500 !important",
        fontFamily: "Euclid Circular A !important",
        width: '100%',

        '@media (max-width: 400px)': {
            padding: '16px 14px !important',
            height:'44px'
        },
    },
}));

export const DocInformation = () => {
    const classes = useStyles();
    const navigate = useNavigate();
    const { displayAlert } = useAlert();
    const { CREATE_PAYMENT_URL, CREATE_CONSULTATION } = useFetchAPI();
    const { loading: appLoading, setLoading: setAppLoading } = useContext(AppContext);
    const { profile } = useContext(ProfileContext);
    
    const docData = safeGetLocalStorageJSON('doctor');
    const docProfile = docData?.profile;

    const data2 = localStorage.getItem('formData');
    const consultInfo = JSON.parse(data2);
    const isoString = convertToISOString(consultInfo?.date, consultInfo?.time);
    const providerId = process.env.REACT_APP_PROVIDER_KEY

    const [loading, setLoading] = useState(false);
    const [firstNameError, setFirstNameError] = useState("");
    const [lastNameError, setLastNameError] = useState("");
    const [phoneError, setPhoneError] = useState("");
    const [genderError, setGenderError] = useState("");
    const [firstName, setFirstName] = useState(profile?.firstName || '');
    const [lastName, setLastName] = useState(profile?.lastName || '');
    const [phone, setPhone] = useState(profile?.phone || '');
    const [gender, setGender] = useState(profile?.gender || '');
    const callbackUrl = process.env.REACT_APP_FRONTEND_URL;

    const createConsultation = async () => {

        if (!firstName) {
            setFirstNameError('This field is required!');
        }
        if (!lastName) {
            setLastNameError('This field is required!');
        }
        if (!phone) {
            setPhoneError('This field is required!');
        }
        if (!gender) {
            setGenderError('This field is required!');
        }

        if (!firstName || !lastName || !phone || !gender) {
            return;
        }

        const personalInfo = {
            firstName,
            lastName,
            phone,
            gender,
        };

        localStorage.setItem('info', JSON.stringify(personalInfo));

        console.log(isoString,'isoStringisoString')

        try {
            setLoading(true);
            setAppLoading(true);
            const consultationVar = {
                consultationOwner: 'myself',
                patientData: {
                    email: consultInfo?.email,
                    firstName: firstName,
                    lastName: lastName,
                    phoneNumber: phone,
                    gender: gender,
                },
                doctor: docProfile._id,
                discomfortLevel: consultInfo?.level,
                description: consultInfo?.aob || undefined,
                firstNotice: consultInfo?.expect,
                providerId,
                contactMedium: consultInfo?.channel,
                time: consultInfo?.time,
                symptoms: consultInfo?.batches.length > 0 && consultInfo?.symptoms.length > 0
                ?  [...consultInfo?.batches.map((batch) => ({ name: batch })), { name: consultInfo?.symptoms }]
                : consultInfo?.batches.length > 0 ? consultInfo?.batches.map((batch) => ({name: batch})) 
                : consultInfo?.symptoms.length > 0 ? [{ name: consultInfo?.symptoms }] 
                : [],
                type: "scheduled",
                timeOffset: "0.00",
                status:"pending",
                createdThrough: "doctor-direct"
            }
            const res = await CREATE_CONSULTATION(consultationVar);
            localStorage.setItem("consultationInfo", JSON.stringify(res?.data?.data));
            setLoading(false);
            const paymentUrlResponse = await CREATE_PAYMENT_URL({
                email: consultInfo?.email,
                reason: "consultation",
                itemId: res?.data?.data?._id,
                callback_url: `${callbackUrl}doctors/consultsuccess`,
                amount: docProfile?.fee
            });
            const paymentInitResponse = paymentUrlResponse?.data?.data.paymentInitResponse
            const reference = paymentInitResponse?.reference;
            const url = paymentInitResponse?.authorization_url;
            localStorage.setItem("psRef", reference);
            window.open(url, '_self');
            setAppLoading(false);
        } catch (error) {
            displayAlert("error", "Initiating consultation failed!");
            console.error("Failed to create consultation", error);
            setAppLoading(false);   
            setLoading(false);   
        }
    }

    return (
        <div className='bg-secondary mid:justify-center justify-start mid:items-center w-full mid:h-screen min-h-screen flex mid:flex-row flex-col px-[18px] mid:px-0'>
            <Arrow className='mid:hidden block my-[19px] cursor-pointer w-6 h-6' onClick={() => navigate('/doctor/preference')}/>
            <div className='bg-white mid:rounded-[16px] heala-border w-full mid:w-[912px] h-full mid:h-[auto]'>
                <div className='flex w-full h-full '>
                    <div className='hidden mid:block w-[31%] h-full border-r border-[#CAC4D0] px-4'>
                        <Arrow className='my-[19px] cursor-pointer' onClick={() => navigate('/doctor/preference')}/>
                        <img src={docProfile?.picture} alt='doc' className='w-[256px] h-[260px] rounded-lg mid:mb-0 mb-4' />
                        <div className='pt-4 pb-6 px-5 flex flex-col gap-1'>
                            <h5 className='text-[16px] font-medium text-[#0C1322]'>Profile </h5>
                            <p className='text-[#0C1322] text-[16px]'>Dr. {docProfile?.firstName} {docProfile?.lastName}</p>
                            <p className='text-[11px] text-tertiary'>{docProfile?.specialization}</p>
                            <div className='text-tertiary flex gap-1 items-center'>
                                <p className='text-[13px]'>{Number.isInteger(docProfile?.rating) ? `${docProfile?.rating}.0` : docProfile?.rating}</p>
                                <div className='flex gap-1'>
                                    {[...Array(Math.floor(docProfile?.rating || 0))].map((_, index) => (
                                        <Star key={index} />
                                    ))}
                                </div>
                            </div>
                            <div className='rounded-lg bg-secondary p-2 heala-border flex items-center'>
                                <p className='text-[13px]'>Consultation fee :</p>
                                <p className='text-[16px] text-primary font-medium ml-[7px]'>N{numberWithCommas(docProfile?.fee)}</p>
                            </div>
                            <p className='text-tertiary text-[13px]'>Heala ID : {docProfile?.dociId ? docProfile.dociId.substring(6) : ''}</p>
                        </div>
                    </div>

                    <div className='flex flex-col gap-[5px] flex-1 py-6 px-4 mid:px-11 mid:py-[19px] heala-info-error'>
                        <div className='mb-[3px]'>
                            <h3 className='text-[20px] font-medium'>Personal Information</h3>
                            <p className='text-[13px]'>Enter a few personal information for record </p>
                        </div>
                        
                        <div>
                            <h3 className="text-[#0C1322] mb-1 text-[13px]">First Name</h3>
                            <Input
                                type="text"
                                placeholder="First Name"
                                fullWidth
                                value={firstName}
                                onChange={(e) =>
                                    { 
                                        setFirstName(e.target.value)
                                        setFirstNameError('')
                                    }
                                }
                                errormessage={firstNameError}
                            />
                        </div>
                        
                        <div>
                            <h3 className="text-[#0C1322] mb-1 text-[13px]">Last Name</h3>
                            <Input
                                type="text"
                                placeholder="Last Name"
                                fullWidth
                                value={lastName}
                                onChange={(e) =>
                                    { 
                                        setLastName(e.target.value)
                                        setLastNameError('')
                                    }
                                }
                                errormessage={lastNameError}
                            />
                        </div>

                        <div className='heala__doc--phone'>
                            <h3 className="text-[#0C1322] mb-1 text-[13px]">Phone Number</h3>
                            <PhoneDropdown country='ng' setPhone={setPhone} phone={phone} errormessage={phoneError} seterrormessage={setPhoneError} />
                        </div>

                        <div className='mb-10 mid:mb-0'>
                            <h3 className="text-[#0C1322] mb-1 text-[13px]">Gender</h3>
                            <FormControl sx={{ mb: 1, minWidth: 120 }}>
                                <Select
                                    labelId="demo-simple-select-helper-label"
                                    id="demo-simple-select-helper"
                                    value={gender}
                                    onChange={(e) =>
                                        { 
                                            setGender(e.target.value)
                                            setGenderError('')
                                        }
                                    }
                                >
                                    <MenuItem value='male'>Male</MenuItem>
                                    <MenuItem value='female'>Female</MenuItem>
                                </Select>
                            </FormControl>
                            {genderError && <p className='text-error'>{genderError}</p>}
                        </div>

                        <Button
                            variant='contained'
                            onClick={createConsultation}
                            className={classes.submitButton}
                        >
                            {loading ?
                                (<CircularProgress
                                    style={{ width: 20, height: 20, color: "#fff" }}
                                />)
                                : 'Book Appointment'}
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    )
}
