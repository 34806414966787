import React from 'react'
import { ReactComponent as Star } from '../../assets/svgs/🦆 emoji _white medium star_.svg';
import SelectField from './field';
import logo from "../../assets/Logo.svg";
import { makeStyles } from '@mui/styles';
import { numberWithCommas } from '../helperFunctions';

export default function Select(

    {
        doc,
        time,
        setTime,
        channel,
        setChannel,
        symptoms,
        setSymptoms,
        level,
        setLevel,
        expect,
        setExpect,
        aob,
        setAob,
        setFee,
        batches,
        setBatches,
        handleReset,
        selectedDate,
        setSelectedDate,
        setEmail,
        email,
        firstName,
        setFirstName,
        lastName,
        setLastName,
        phone,
        setPhone,
        gender,
        setGender,
        createConsultation
    }
) {
    const handleRedirect = () => {
        window.open("https://heala.ng/terms/", "_blank");
    }
    return (
        <div className='mid:w-auto w-full mid:mt-3 mid:mb-6' style={{ position: "relative" }}>

            <div className="ribbon ribbon-top-right">
                <div>
                    <p className='text_fix'>Powered By</p>
                    <img src='https://heala.ng/wp-content/uploads/2022/10/heala_logo_2-1.svg' className='img_fix' alt="heala logo" />
                </div>

            </div>

            <div className='bg-white mid:w-auto w-full mid:mt-3 mid:mb-6 rounded-lg mid:p-12 p-5'>
                <div className='flex flex-col gap-7 font-euclid'>

                    <div className='mt-1 flex gap-3'>

                        <div className='flex self-center'>
                            <img src={doc.picture} alt='doctor' className="w-16 h-16 rounded-full" />
                        </div>
                        <div>
                            <h3 className="font-medium text-[20px]" style={{ fontWeight: '600' }}>{doc.lastName} {doc.firstName}</h3>
                            <p className="font-normal text-[16px] ">Consultation Fee: <span style={{ color: "#0971f1" }}><strong>₦{numberWithCommas(doc?.fee)}</strong></span></p>
                            {doc.rating ?
                                <div className='flex items-center gap-1'>
                                    {[...Array(Math.floor(doc?.rating))].map((_, index) => (
                                        <Star key={index} />
                                    ))}
                                    <p className="font-normal text-[11px]">
                                        {Number.isInteger(doc.rating) ? `${doc.rating}.0` : doc.rating}
                                    </p>
                                </div>
                                : ''}


                        </div>

                    </div>

                    <div className=''>
                        <SelectField
                            doc={doc}
                            time={time}
                            setFee={setFee}
                            handleReset={handleReset}
                            setTime={setTime}
                            channel={channel}
                            setChannel={setChannel}
                            symptoms={symptoms}
                            setSymptoms={setSymptoms}
                            level={level}
                            setLevel={setLevel}
                            expect={expect}
                            setExpect={setExpect}
                            aob={aob}
                            setAob={setAob}
                            batches={batches}
                            setBatches={setBatches}
                            setSelectedDate={setSelectedDate}
                            selectedDate={selectedDate}
                            setEmail={setEmail}
                            email={email}
                            phone={phone}
                            setPhone={setPhone}
                            firstName={firstName}
                            lastName={lastName}
                            gender={gender}
                            setFirstName={setFirstName}
                            setLastName={setLastName}
                            setGender={setGender}
                            createConsultation={createConsultation}

                        />
                    </div>
                </div>

            </div >
            <div style={{ padding: "5%" }} className="flex flex-col items-center .items-center_fix">
                <div className="flex justify-center items-center space-x-1">
                    <p className="leading-5 m-0">powered by</p>
                    <img src={logo} alt="heala logo" />
                </div>
                <p onClick={handleRedirect} className="mid:block hidden text-primary cursor-pointer text-[13px] m-0 mt-[5px] mb-[10px]">
                    Heala's Terms & Conditions
                </p>
            </div>
        </div>
    )
}
