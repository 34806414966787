import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, TextField } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { CircularProgress } from "@mui/material";

const useSelectConfirmStyles = makeStyles(() => ({
    cancelButton: {
        padding: "16px 24px !important",
        borderRadius: "8px !important",
        textTransform: "capitalize !important",
        borderColor: "#3E5EA9 !important",
        color: "#3E5EA9 !important",
        fontSize: "13px !important",
        fontWeight: "500 !important",
        fontFamily: "Euclid Circular A !important",
        width:'100%',

        '@media (max-width: 400px)': {
            padding: '16px 14px !important',
        },
    },
    submitButton: {
        padding: "18px 24px !important",
        borderRadius: "8px !important",
        textTransform: "capitalize !important",
        backgroundColor: "#3E5EA9 !important",
        fontSize: "13px !important",
        fontWeight: "500 !important",
        fontFamily: "Euclid Circular A !important",
        width: '100%',

        '@media (max-width: 400px)': {
            padding: '16px 14px !important',
        },
    },
}));

export default function SelectConfirm(
    { 
        level, 
        expect, 
        aob,
        setConfirmDrawer,
        setDrawer,
        handleReset,
        fee,
        createConsultation,
        loading,
        batches,
        symptoms
    }) {
    const classes = useSelectConfirmStyles();
    const navigate = useNavigate();
    
    const handleCancel = () => {
        if (setConfirmDrawer) {
            handleReset();
            setConfirmDrawer(false);
            setDrawer(false);
        } else {
            navigate('/doctors');
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        createConsultation();      
    }
    
    return (
        <form onSubmit={handleSubmit}>
            <div className='mb-6'>
                <p>What are your symptoms?</p>
                <TextField
                    id="filled-read-only-input"
                    value={
                        batches.length > 0 && symptoms.length > 0
                            ? `${batches.join(', ')}, ${symptoms}`
                            : batches.length > 0
                            ? batches.join(', ')
                            : symptoms.length > 0
                            ? symptoms
                            : ''
                    }
                    InputProps={{
                        readOnly: true,
                    }}
                    variant="filled"
                    disabled
                />
            </div>
            <div className='mb-6'>
                <p>What is your discomfort level?</p>
                <TextField
                    id="filled-read-only-input"
                    value={level || ""}
                    InputProps={{
                        readOnly: true,
                    }}
                    disabled
                    variant="filled"
                />
            </div>
            <div className='mb-6'>
                <p>When did you start experiencing the symptoms?</p>
                <TextField
                    id="filled-read-only-input"
                    value={expect || ""}
                    InputProps={{
                        readOnly: true,
                    }}
                    disabled
                    variant="filled"
                />
            </div>
            <div className='custom-input mb-6'>
                <p>Please share any other information related to your symptom</p>
                <TextField
                    id="filled-read-only-input"
                    value={aob || ""}
                    InputProps={{
                        readOnly: true,
                    }}
                    disabled
                    variant="filled"
                />
            </div>
            <div className='mt-10 font-medium text-[25px] flex justify-between'>
                <h3>Consultation Fee:</h3>
                <h3 className='text-primary'>N{fee}</h3>
            </div>
            <div className='mt-12 w-full flex justify-center gap-4'>
                <Button 
                    variant='outlined' 
                    onClick={handleCancel}
                    className={classes.cancelButton}
                >
                    Cancel
                </Button>
                <Button 
                    variant='contained' 
                    type='submit'
                    className={classes.submitButton}
                >
                    {loading ? 
                        (<CircularProgress
                            style={{ width: 20, height: 20, color: "#fff" }}
                        /> ) 
                    : "Confirm & Pay"}
                </Button>
            </div>
        </form>
    )
}
