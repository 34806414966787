import { format, isAfter, parseISO } from "date-fns";
import dayjs from "dayjs";
import { hourFormat } from "./mockData";

export const removeEmptyStringValues = (obj) => {
  try {
    let newObj = {};
    for (const key in obj) {
      if (obj[key] !== "") {
        newObj[key] = obj[key];
      }
    }
    return newObj;
  } catch (err) {
    console.err("error from removeEmptyStringValues FN", err);
    return obj;
  }
};

export const filterObjByKeys = (obj, keys = ["firstName"]) => {
  try {
    let newObj = {};
    keys.map((key) => {
      newObj[key] = obj[key];
      return null;
    });
    return newObj;
  } catch (err) {
    console.err("error from filterObjByKeys FN", err);
    return obj;
  }
};

export const getYesterdaysDate = () => {
  const timeStamp = dayjs(new Date());
  return timeStamp.subtract(1, "day");
  /* const timeStamp = new Date().getTime();
  return timeStamp - 24*60*60*1000; */
};

export const getSevenDaysFromNow = () => {
  const timeStamp = dayjs(new Date());
  return timeStamp.add(7, "day");
};

export const formatTime = (time) => {
  try {
    const hour = Number(time.split(":")[0]);
    const mins = time.split(":")[1];
    return hour < 12
      ? `${hourFormat[`${hour}`]}:${mins} am`
      : `${hourFormat[`${hour}`]}:${mins} pm`;
  } catch (error) {
    console.error("Error from formatTime func. :", error);
  }
};

export const isSelectedTimePassed = (time = "09:00", selectedDate) => {
  try {
    const hour = Number(time.split(":")[0]);
    const mins = time.split(":")[1];
    const now = Date.now();
    const date = new Date(Date.parse(selectedDate));
    date.setHours(hour - 1);
    date.setMinutes(mins);
    date.setSeconds(0);
    date.setMilliseconds(0);
    return isAfter(now, date);
  } catch (error) {
    console.log("Error from isTimePassed func:", error);
  }
};

export const formatDateTime = (date, time) =>  `${date}T${time}:00+01:00`;
// {
//   try {
//     const hours = Number(time.split(":")[0]) + 1;
//     const minutes = time.split(":")[1];
//     const formattedDateTime = set(new Date(Date.parse(date)), {
//       hours,
//       minutes,
//     });
//     console.log("formatted time", `${date}T${time}:00+1:00` );
//     return formattedDateTime;
//   } catch (error) {
//     console.log("Error from formatDateTime func:", error);
//   }
// };


export const createConsultationVar = (formValues, providerId) => {
  try {
    const {
      firstName,
      lastName,
      email,
      phoneNumber,
      gender,
      doctor,
      symptoms, 
      discomfortLevel, 
      description, 
      firstNotice, 
      time,
      contactMedium
    } = formValues
    return {
      consultationOwner: "Myself",
      patientData: {
        email,
        firstName,
        lastName,
        phoneNumber,
        gender,
        hmoId: "",
        image: "",
        height: "0",
        weight: "0",
        bloodGroup: "",
        genotype: "",
      },
      symptoms,
      discomfortLevel, 
      description,
      firstNotice,
      doctor,
      time,
      type: "scheduled",
      status: "pending",
      providerId,
      contactMedium,
      timeOffset: "",
    };
  } catch (error) {
    console.error("Error from createConsultationVar func.:", error)
  }
};

export const isDevelopment = () => {
  return process.env.NODE_ENV === "development";
};

export const getDateAndTime = (time)=>{
  try {
    const convertedTime = parseISO(time);
    return `${format(convertedTime, "PPPP")} - ${format(
      convertedTime,
      "p"
    )}`
  } catch (error) {
    console.error("Error getting date and time:", error);
  }
}

export function safeGetLocalStorageJSON(key) {
  try {
    const data = localStorage.getItem(key);
    return data ? JSON.parse(data) : null;
  } catch (error) {
    console.error('Error parsing JSON from localStorage:', error);
    return null;
  }
}
